import { defineStore } from "pinia";
import http from "../http-common";
import { computed, ref } from "vue";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import { generateResume } from "../helpers/resumeGenerator";

import {
  ContactDetails,
  ContactDetailsDto,
  LicenceDto,
  QualificationDto,
  CreateResumeDto,
  WorkExperienceDto,
  Licence,
  Qualification,
  Resume,
  UpdateResumeDto,
  WorkExperience,
} from "./models/resume.models";

export const useResumeStore = defineStore("resume", () => {
  /* --- States --- */
  const resumeRef = ref({
    isLoading: false,
    data: null as Resume | null,
  });

  const contactDetailsForm = ref({
    isLoading: false,
    isInitiated: false,
    data: null as ContactDetails | null,
  });

  const workExperienceForm = ref({
    isLoading: false,
    isInitiated: false,
    data: null as WorkExperience | null,
  });

  const licenceForm = ref({
    isLoading: false,
    isInitiated: false,
    data: null as Licence | null,
  });

  const qualificationForm = ref({
    isLoading: false,
    isInitiated: false,
    data: null as Qualification | null,
  });

  /* --- Getters --- */

  /* Resume */
  const resume = computed<Resume | null>(() => {
    return resumeRef.value.data;
  });

  const isResumeLoading = computed<boolean>(() => {
    return resumeRef.value.isLoading;
  });

  const resumeId = computed<string | undefined>(() => {
    return resumeRef.value.data?.id;
  });

  const resumeWorkExperiences = computed<Array<WorkExperience> | undefined>(
    () => {
      return resumeRef.value.data?.workExperiences;
    }
  );

  const resumeLicences = computed<Array<Licence> | undefined>(() => {
    return resumeRef.value.data?.licences;
  });

  const resumeQualifications = computed<Array<Qualification> | undefined>(
    () => {
      return resumeRef.value.data?.qualifications;
    }
  );

  const resumeContactDetails = computed<ContactDetails | null | undefined>(
    () => {
      return resumeRef.value.data?.contactDetails;
    }
  );

  const resumePersonalStatement = computed<string | undefined>(() => {
    return resumeRef.value.data?.personalStatement;
  });

  /* Form values */
  const contactDetailsFormData = computed<ContactDetails | null>(() => {
    return contactDetailsForm.value.data;
  });

  const isContactDetailsFormInit = computed<boolean>(() => {
    return contactDetailsForm.value.isInitiated;
  });

  const workExperienceFormData = computed<WorkExperience | null>(() => {
    return workExperienceForm.value.data;
  });

  const isWorkExperienceFormInit = computed<boolean>(() => {
    return workExperienceForm.value.isInitiated;
  });

  const licenceFormData = computed<Licence | null>(() => {
    return licenceForm.value.data;
  });

  const isLicenceFormInit = computed<boolean>(() => {
    return licenceForm.value.isInitiated;
  });

  const qualificationFormData = computed<Qualification | null>(() => {
    return qualificationForm.value.data;
  });

  const isQualificationFormInit = computed<boolean>(() => {
    return qualificationForm.value.isInitiated;
  });

  const isDetailsCompleted = computed<boolean | undefined>(() => {
    return resumeRef.value.data?.isContactDetailsCompleted;
  });

  const isExperienceCompleted = computed<boolean | undefined>(() => {
    return resumeRef.value.data?.isWorkExperiencesCompleted;
  });

  const isQualificationsCompleted = computed<boolean | undefined>(() => {
    return resumeRef.value.data?.isQualificationsCompleted;
  });
  const isLicencesCompleted = computed<boolean | undefined>(() => {
    return resumeRef.value.data?.isLicencesCompleted;
  });

  const isPersonalStatementCompleted = computed<boolean>(() => {
    return resumeRef.value.data?.personalStatement ? true : false;
  });

  /* --- DTO transformations -- */
  const qualificationDto = computed<QualificationDto | undefined>(() => {
    if (!qualificationForm.value.data) return;
    return omitKeys(
      qualificationForm.value.data,
      "id",
      "createdAt",
      "updatedAt",
      "resume"
    );
  });

  const licenceDto = computed<LicenceDto | undefined>(() => {
    if (!licenceForm.value.data) return;
    return omitKeys(
      licenceForm.value.data,
      "id",
      "createdAt",
      "updatedAt",
      "resume"
    );
  });

  const workExperienceDto = computed<WorkExperienceDto | undefined>(() => {
    if (!workExperienceForm.value.data) return;
    return omitKeys(
      workExperienceForm.value.data,
      "id",
      "createdAt",
      "updatedAt",
      "resume"
    );
  });

  const contactDetailsDto = computed<ContactDetailsDto | undefined>(() => {
    if (!contactDetailsForm.value.data) return;
    return omitKeys(
      contactDetailsForm.value.data,
      "id",
      "createdAt",
      "updatedAt",
      "resume"
    );
  });

  /* --- Initiators --- */

  /**
   * Populates the form inputs with existing values or empty if no values exist.
   */
  const initContactDetailsForm = () => {
    if (resumeContactDetails.value) {
      contactDetailsForm.value.data = { ...resumeContactDetails.value };
    } else {
      contactDetailsForm.value.data = {
        id: "",
        createdAt: "",
        updatedAt: "",
        resume: "",
        hideLocation: false,
        linkedinId: "",
      };
    }
    contactDetailsForm.value.isInitiated = true;
  };

  /**
   * Populates the form inputs with existing values from the provided id.
   * Creates an empty states if no id is provided.
   */
  const initWorkExperienceForm = (experienceId?: string) => {
    if (experienceId && resumeWorkExperiences.value) {
      const value = resumeWorkExperiences.value?.find(
        (experience) => experience.id === experienceId
      );
      if (!value) {
        throw new Error("Unable to find the item with the provided id");
      }
      workExperienceForm.value.data = { ...value };
    } else {
      workExperienceForm.value.data = {
        id: "",
        createdAt: "",
        updatedAt: "",
        resume: "",
        business: "",
        role: "",
        startDate: "",
        endDate: "",
        isCurrentRole: false,
        businessDescription: "",
        impact: "",
      };
    }
    workExperienceForm.value.isInitiated = true;
  };

  /**
   * Populates the form inputs with existing values from the provided id.
   * Creates an empty states if no id is provided.
   */
  const initLicenceForm = (licenceId?: string) => {
    if (licenceId && resumeLicences.value) {
      const value = resumeLicences.value?.find(
        (licence) => licence.id === licenceId
      );
      if (!value) {
        throw new Error("Unable to find the item with the provided id");
      }
      licenceForm.value.data = { ...value };
    } else {
      licenceForm.value.data = {
        id: "",
        createdAt: "",
        updatedAt: "",
        resume: "",
        name: "",
        issuer: "",
        issueDate: "",
        expiryDate: "",
        description: "",
      };
    }
    licenceForm.value.isInitiated = true;
  };

  /**
   * Populates the form inputs with existing values from the provided id.
   * Creates an empty states if no id is provided.
   */
  const initQualificationForm = (qualificationId?: string) => {
    if (qualificationId && resumeQualifications.value) {
      const value = resumeQualifications.value?.find(
        (qualification) => qualification.id === qualificationId
      );
      if (!value) {
        throw new Error("Unable to find the item with the provided id");
      }
      qualificationForm.value.data = { ...value };
    } else {
      qualificationForm.value.data = {
        id: "",
        createdAt: "",
        updatedAt: "",
        resume: "",
        institution: "",
        name: "",
        isQualificationComplete: true,
        startDate: "",
        endDate: "",
        highlights: "",
      };
    }
    qualificationForm.value.isInitiated = true;
  };

  /* --- Setters --- */

  /**
   * These setters keep the form refs values updated
   * @example setLicenceFormValue("name", "Forklift Operator")
   */

  const setContactDetailsFormValue = <K extends keyof ContactDetails>(
    key: K,
    value: ContactDetails[K]
  ) => {
    if (!contactDetailsForm.value.data) {
      return;
    }
    contactDetailsForm.value.data[key] = value;
  };

  const setWorkExperienceFormValue = <K extends keyof WorkExperience>(
    key: K,
    value: WorkExperience[K]
  ) => {
    if (!workExperienceForm.value.data) {
      return;
    }
    workExperienceForm.value.data[key] = value;
  };

  const setLicenceFormValue = <K extends keyof Licence>(
    key: K,
    value: Licence[K]
  ) => {
    if (!licenceForm.value.data) {
      return;
    }
    licenceForm.value.data[key] = value;
  };

  const setQualificationFormValue = <K extends keyof Qualification>(
    key: K,
    value: Qualification[K]
  ) => {
    if (!qualificationForm.value.data) {
      return;
    }
    qualificationForm.value.data[key] = value;
  };

  const setPersonalStatementValue = (value: string) => {
    if (!resumeRef.value.data) return;
    resumeRef.value.data.personalStatement = value;
  };

  const setContactDetailsFormInit = (value: boolean) => {
    contactDetailsForm.value.isInitiated = value;
  };

  const setWorkExperienceFormInit = (value: boolean) => {
    workExperienceForm.value.isInitiated = value;
  };

  const setLicenceFormInit = (value: boolean) => {
    licenceForm.value.isInitiated = value;
  };

  const setQualificationFormInit = (value: boolean) => {
    qualificationForm.value.isInitiated = value;
  };

  /* --- API requests --- */

  /**
   * Requests the Participant's resume
   * @param id (optional) - Resume id. If not provided will retrieve by participant id
   */
  const requestResume = async (id?: string) => {
    const path = id ? `resume/${id}` : "resume";
    resumeRef.value.isLoading = true;
    try {
      const res: { data: Resume } = await http.get(path);
      resumeRef.value.data = res.data;
    } finally {
      resumeRef.value.isLoading = false;
    }
  };

  /**
   * Creates an initial resume in the database
   * @param dto (optional) - the request body
   */
  const createResume = async (dto?: CreateResumeDto) => {
    const path = "resume";
    resumeRef.value.isLoading = true;
    const body: CreateResumeDto = dto
      ? dto
      : {
          isContactDetailsCompleted: false,
          isWorkExperiencesCompleted: false,
          isQualificationsCompleted: false,
          isLicencesCompleted: false,
        };
    try {
      const res: { data: Resume } = await http.post(path, body);
      resumeRef.value.data = res.data;
    } finally {
      resumeRef.value.isLoading = false;
    }
  };

  /**
   * Updates a property from a resume. e.g. isLicencesCompleted
   * @param key the property to update
   * @param value the value of the property
   */
  const updateResumeProperty = async (
    key: keyof UpdateResumeDto,
    value: UpdateResumeDto[keyof UpdateResumeDto]
  ) => {
    if (!resumeRef.value?.data) return;
    const resumeId = resumeRef.value.data.id;
    const path = `resume/${resumeId}`;
    const body: UpdateResumeDto = { [key]: value };
    resumeRef.value.isLoading = true;
    try {
      const res: { data: Resume } = await http.put(path, body);
      resumeRef.value.data[key] = res.data[key];
    } finally {
      resumeRef.value.isLoading = false;
    }
  };

  /**
   * Updates the personal statement value
   * @param value the string value of the personal statement
   */
  const updatePersonalStatement = async () => {
    if (!resumeRef.value?.data) return;
    const resumeId = resumeRef.value.data.id;
    const path = `resume/${resumeId}`;
    const body = { personalStatement: resumePersonalStatement.value };
    resumeRef.value.isLoading = true;
    try {
      const res: { data: Resume } = await http.put(path, body);
      resumeRef.value.data.personalStatement = res.data.personalStatement;
    } finally {
      resumeRef.value.isLoading = false;
    }
  };

  /**
   * Creates a new qualification record in the Database
   * @param resumeId the id of the corresponding Resume
   */
  const createQualification = async () => {
    if (!resumeRef.value.data || !qualificationDto.value || !resumeId.value) {
      return;
    }
    qualificationForm.value.isLoading = true;
    const path = `resume/${resumeId.value}/qualification`;
    const body: QualificationDto = qualificationDto.value;
    try {
      const res: { data: Qualification } = await http.post(path, body);
      resumeRef.value.data.qualifications = [
        ...(resumeRef.value.data.qualifications || []),
        res.data,
      ];
    } finally {
      qualificationForm.value.isLoading = false;
    }
  };

  /**
   * Creates a new Work Experience record in the Database
   * @param resumeId the id of the corresponding Resume
   */
  const createWorkExperience = async () => {
    if (!resumeRef.value.data || !workExperienceDto.value || !resumeId.value) {
      return;
    }
    workExperienceForm.value.isLoading = true;
    const path = `resume/${resumeId.value}/work-experience`;
    const body: WorkExperienceDto = workExperienceDto.value;
    try {
      const res: { data: WorkExperience } = await http.post(path, body);
      resumeRef.value.data.workExperiences = [
        ...(resumeRef.value.data.workExperiences || []),
        res.data,
      ];
    } finally {
      workExperienceForm.value.isLoading = false;
    }
  };

  /**
   * Creates a new Licence record in the Database
   * @param resumeId the id of the corresponding Resume
   */
  const createLicence = async () => {
    if (!resumeRef.value.data || !licenceDto.value || !resumeId.value) {
      return;
    }
    licenceForm.value.isLoading = true;
    const path = `resume/${resumeId.value}/licence`;
    const body: LicenceDto = licenceDto.value;
    try {
      const res: { data: Licence } = await http.post(path, body);
      resumeRef.value.data.licences = [
        ...(resumeRef.value.data.licences || []),
        res.data,
      ];
    } finally {
      licenceForm.value.isLoading = false;
    }
  };

  /**
   * Creates a new Contact Details record in the Database
   * @param resumeId the id of the corresponding Resume
   */
  const createContactDetails = async () => {
    if (!resumeRef.value.data || !contactDetailsDto.value || !resumeId.value) {
      return;
    }
    contactDetailsForm.value.isLoading = true;
    const path = `resume/${resumeId.value}/contact-details`;
    const body: ContactDetailsDto = contactDetailsDto.value;
    try {
      const res: { data: ContactDetails } = await http.post(path, body);
      resumeRef.value.data.contactDetails = res.data;
    } finally {
      contactDetailsForm.value.isLoading = false;
    }
  };

  /**
   * Updates an existing Qualification record in the Database
   * @param qualificationId the id of the corresponding record
   */
  const updateQualification = async (qualificationId: string) => {
    if (
      !qualificationDto.value ||
      !resumeRef.value.data?.qualifications?.find(
        (qualification) => qualification.id === qualificationId
      )
    ) {
      throw new Error("Qualification with provided ID does not exist");
    }
    qualificationForm.value.isLoading = true;
    const path = `resume/qualification/${qualificationId}`;
    const body: QualificationDto = qualificationDto.value;
    try {
      const res: { data: Qualification } = await http.put(path, body);
      resumeRef.value.data.qualifications =
        resumeRef.value.data.qualifications.map((qualification) =>
          qualification.id === res.data.id ? res.data : qualification
        );
    } finally {
      qualificationForm.value.isLoading = false;
    }
  };

  /**
   * Updates an existing Licence record in the Database
   * @param licenceId the id of the corresponding record
   */
  const updateLicence = async (licenceId: string) => {
    if (
      !licenceDto.value ||
      !resumeRef.value.data?.licences?.find(
        (licence) => licence.id === licenceId
      )
    ) {
      throw new Error("Licence with provided ID does not exist");
    }
    licenceForm.value.isLoading = true;
    const path = `resume/licence/${licenceId}`;
    const body: LicenceDto = licenceDto.value;
    try {
      const res: { data: Licence } = await http.put(path, body);
      resumeRef.value.data.licences = resumeRef.value.data.licences.map(
        (licence) => (licence.id === res.data.id ? res.data : licence)
      );
    } finally {
      licenceForm.value.isLoading = false;
    }
  };

  /**
   * Updates an existing Work Experience record in the Database
   * @param workExperienceId the id of the corresponding record
   */
  const updateWorkExperience = async (workExperienceId: string) => {
    if (
      !workExperienceDto.value ||
      !resumeRef.value.data?.workExperiences?.find(
        (experience) => experience.id === workExperienceId
      )
    ) {
      throw new Error("Work Experience with provided ID does not exist");
    }
    workExperienceForm.value.isLoading = true;
    const path = `resume/work-experience/${workExperienceId}`;
    const body: WorkExperienceDto = workExperienceDto.value;
    try {
      const res: { data: WorkExperience } = await http.put(path, body);
      resumeRef.value.data.workExperiences =
        resumeRef.value.data.workExperiences.map((experience) =>
          experience.id === res.data.id ? res.data : experience
        );
    } finally {
      workExperienceForm.value.isLoading = false;
    }
  };

  /**
   * Updates an existing Contact Detials record in the Database
   * @param contactDetailsId the id of the corresponding record
   */
  const updateContactDetails = async (contactDetailsId: string) => {
    if (!contactDetailsDto.value || !resumeRef.value.data?.contactDetails) {
      throw new Error("Contact details does not exist");
    }
    contactDetailsForm.value.isLoading = true;
    const path = `resume/contact-details/${contactDetailsId}`;
    const body: ContactDetailsDto = contactDetailsDto.value;
    try {
      const res: { data: ContactDetails } = await http.put(path, body);
      resumeRef.value.data.contactDetails = res.data;
    } finally {
      contactDetailsForm.value.isLoading = false;
    }
  };

  const deleteQualification = async (qualificationId: string) => {
    if (
      !resumeRef.value.data?.qualifications?.find(
        (qualification) => qualification.id === qualificationId
      )
    ) {
      throw new Error("Qualification with provided ID does not exist");
    }
    qualificationForm.value.isLoading = true;
    const path = `resume/qualification/${qualificationId}`;
    try {
      await http.delete(path);
      resumeRef.value.data.qualifications =
        resumeRef.value.data.qualifications.filter(
          (qualification) => qualification.id !== qualificationId
        );
      if (resumeRef.value.data.qualifications.length === 0) {
        updateResumeProperty("isQualificationsCompleted", false);
      }
    } finally {
      qualificationForm.value.isLoading = false;
    }
  };

  const deleteLicence = async (licenceId: string) => {
    if (
      !resumeRef.value.data?.licences?.find(
        (licence) => licence.id === licenceId
      )
    ) {
      throw new Error("Licence with provided ID does not exist");
    }
    licenceForm.value.isLoading = true;
    const path = `resume/licence/${licenceId}`;
    try {
      await http.delete(path);
      resumeRef.value.data.licences = resumeRef.value.data.licences.filter(
        (licence) => licence.id !== licenceId
      );
      if (resumeRef.value.data.licences.length === 0) {
        updateResumeProperty("isLicencesCompleted", false);
      }
    } finally {
      licenceForm.value.isLoading = false;
    }
  };

  const deleteWorkExperience = async (workExperienceId: string) => {
    if (
      !resumeRef.value.data?.workExperiences?.find(
        (experience) => experience.id === workExperienceId
      )
    ) {
      throw new Error("Work Experience with provided ID does not exist");
    }
    workExperienceForm.value.isLoading = true;
    const path = `resume/work-experience/${workExperienceId}`;
    try {
      await http.delete(path);
      resumeRef.value.data.workExperiences =
        resumeRef.value.data.workExperiences.filter(
          (experience) => experience.id !== workExperienceId
        );
      if (resumeRef.value.data.workExperiences.length === 0) {
        updateResumeProperty("isWorkExperiencesCompleted", false);
      }
    } finally {
      workExperienceForm.value.isLoading = false;
    }
  };

  const downloadResumeToDoc = () => {
    return new Promise<boolean>((resolve, reject) => {
      const doc = generateResume(resumeRef.value.data);
      const filename = `Resume_${resumeRef.value.data?.participant.givenName}${resumeRef.value.data?.participant.familyName}.docx`;
      Packer.toBlob(doc)
        .then((blob) => {
          saveAs(blob, filename);
          resolve(true);
        })
        .catch((error) => {
          //console.error("Error creating document:", error);
          reject(false);
        });
    });
  };

  /**
   * Omits an object keys to convert it to the desired type
   * @param obj the original object
   * @param keys the undesired keys to omit
   * @returns an object with the desired keys
   */
  function omitKeys<T extends Object, K extends keyof T>(
    obj: T,
    ...keys: K[]
  ): Omit<T, K> {
    const newObj = {} as T;
    Object.keys(obj).forEach((key) => {
      if (!keys.includes(key as K)) {
        newObj[key as K] = obj[key as K];
      }
    });
    return newObj as Omit<T, K>;
  }

  return {
    resumeRef,
    resume,
    isResumeLoading,
    resumeId,
    resumeLicences,
    resumeWorkExperiences,
    resumeQualifications,
    resumeContactDetails,
    resumePersonalStatement,
    contactDetailsFormData,
    licenceFormData,
    qualificationFormData,
    workExperienceFormData,
    isContactDetailsFormInit,
    isWorkExperienceFormInit,
    isLicenceFormInit,
    isQualificationFormInit,
    contactDetailsForm,
    qualificationForm,
    workExperienceForm,
    licenceForm,
    isDetailsCompleted,
    isExperienceCompleted,
    isQualificationsCompleted,
    isLicencesCompleted,
    isPersonalStatementCompleted,
    setContactDetailsFormValue,
    setLicenceFormValue,
    setQualificationFormValue,
    setWorkExperienceFormValue,
    setPersonalStatementValue,
    setContactDetailsFormInit,
    setWorkExperienceFormInit,
    setLicenceFormInit,
    setQualificationFormInit,
    initContactDetailsForm,
    initLicenceForm,
    initQualificationForm,
    initWorkExperienceForm,
    requestResume,
    createResume,
    updateResumeProperty,
    updatePersonalStatement,
    createContactDetails,
    createLicence,
    createQualification,
    createWorkExperience,
    updateContactDetails,
    updateLicence,
    updateQualification,
    updateWorkExperience,
    deleteQualification,
    deleteLicence,
    deleteWorkExperience,
    downloadResumeToDoc,
  };
});
